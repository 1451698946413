<template>
  <div>
    <a-form ref="formRef" layout="vertical" style="width: 100%">
      <a-form-item
        v-for="(item, index) in ListData"
        :key="item.key"
        :label="index === 0 ? '电话' : ''"
        :name="['formState', 'infoList', index, 'phone']"
        :rules="[required]"
        class="formItem"
      >
        <a-input
          v-model:value="item.phone"
          placeholder="请输入联系人电话"
          style="width: 93%; margin-right: 8px; height: 32px"
        />
        <!--      :disabled="dynamicValidateForm.domains.length === 1"
                 v-if="dynamicValidateForm.domains.length > 1"
      -->
        <MinusCircleFilled
          class="dynamic-delete-button"
          @click="removeDomain(item)"
        />
      </a-form-item>
      <!--    v-bind="formItemLayoutWithOutLabel"  -->
      <a-form-item>
        <a-button type="dashed" style="width: 100%" @click="addDomain">
          添加联系人电话
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { Form, message } from 'ant-design-vue';
import { MinusCircleFilled } from '@ant-design/icons-vue';
import { reactive, watch, toRefs } from 'vue';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    MinusCircleFilled,
  },
  props: {
    visible: Boolean,
    list: { type: Array, default: () => [] },
  },
  setup(props) {
    const state = reactive({ ListData: props.list });
    const required = { required: true, whitespace: true, message: '不能为空' };

    watch(
      () => props.list,
      value => (state.infoList = value)
    );

    const removeDomain = item => {
      console.log('item', item, state.ListData);
      if (state.ListData.length === 1) {
        return message.info('至少保留一个电话');
      } else {
        let index = state.ListData.indexOf(item);
        if (index !== -1) {
          state.ListData.splice(index, 1);
        }
      }
    };
    const addDomain = () => {
      if (state.ListData.length === 5) {
        return message.info('最多只能添加5个电话');
      } else {
        state.ListData.push({
          phone: '',
          key: Date.now(),
        });
      }
    };

    return {
      ...toRefs(state),
      required,
      removeDomain,
      addDomain,
    };
  },
};
</script>

<style scoped lang="less">
/*电话号码动态样式*/
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  //color: #999;
  color: #ff7b00;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #ff7b00;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.bt /deep/ .ant-form-item {
  margin-bottom: 0px !important;
  //:deep(.ant-form-item) {
  //margin-bottom: 0px !important;
  //}
}
</style>
